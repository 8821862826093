import React from 'react';

import styled from '@emotion/styled';
import { Text, Image } from 'components/atoms';
import { PageContent } from 'components/layouts';

// ICONS AND IMAGE
import appstore from 'assets/app-store.png';
import googleplay from 'assets/google-play.png';

const StyledDiv = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '.getAppPage__downloadBox': {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 140
  },
  '.getAppPage__highlightText': {
    color: '#f69126'
  },
  '.getAppPage__flexBox': {
    display: 'flex'
  }
});

const GetAppPage = () => {
  return (
    <PageContent>
      <StyledDiv>
        <div className={'getAppPage__paper'}>
          <Text
            textAlign={'center'}
            fontSize={3}
            fontWeight={'bold'}
            color={'grey'}>
            GET THE
            <span className={'getAppPage__highlightText'}>
              {' '}
              BEST EXPERIENCE
            </span>{' '}
            IN
            <span className={'getAppPage__highlightText'}> APP</span>
          </Text>
          <Image
            minHeight={225}
            src={
              'https://firebasestorage.googleapis.com/v0/b/mi-crm-pumpkin.appspot.com/o/user_web%2Fget-app__mockup.png?alt=media&token=a692b5eb-6093-44e3-89c3-0563538d5129'
            }
          />
          <Text
            textAlign={'center'}
            fontSize={3}
            fontWeight={'bold'}
            color={'grey'}>
            Buy
            <span className={'getAppPage__highlightText'}> Vouchers</span>
          </Text>
          <Text
            textAlign={'center'}
            fontSize={3}
            fontWeight={'bold'}
            color={'grey'}>
              Meet
            <span className={'getAppPage__highlightText'}> Membership</span>
          </Text>
          <Text
            textAlign={'center'}
            fontWeight={'bold'}
            color={'lightGrey'}
            fontSize={2}>
            and a lot more...
          </Text>
          <div className={'getAppPage__flexBox'}>
            <div className={'getAppPage__downloadBox'}>
              <a
                href="itms://itunes.apple.com/th/app/pumpkin/id1392855143?mt=8"
                target="_blank"
                rel="noopener noreferrer">
                <Image src={appstore} />
              </a>
            </div>
            <div className={'getAppPage__downloadBox'}>
              <a
                href="market://details?id=com.mi.pumpkin"
                target="_blank"
                rel="noopener noreferrer">
                <Image src={googleplay} />
              </a>
            </div>
          </div>
        </div>
      </StyledDiv>
    </PageContent>
  );
};

GetAppPage.propTypes = {};
GetAppPage.defaultProps = {};

export default GetAppPage;
